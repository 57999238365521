// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-coming-soon-jsx": () => import("./../../../src/pages/coming-soon.jsx" /* webpackChunkName: "component---src-pages-coming-soon-jsx" */),
  "component---src-pages-dev-guide-to-management-js": () => import("./../../../src/pages/dev-guide-to-management.js" /* webpackChunkName: "component---src-pages-dev-guide-to-management-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opt-in-confirmation-jsx": () => import("./../../../src/pages/opt-in-confirmation.jsx" /* webpackChunkName: "component---src-pages-opt-in-confirmation-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-thank-you-dgtmpl-js": () => import("./../../../src/pages/thank-you-dgtmpl.js" /* webpackChunkName: "component---src-pages-thank-you-dgtmpl-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

